import * as React from 'react';
import { mergeStyles, Styles } from 'react-select';
import styled, { useEmotionTheme, EmotionTheme, EmotionStyle } from '../../../core/styled';
import Select, { OptionType } from '../../../components/Select';
import commonStyles from '../../../constants/commonStyles';
import View from '../../../components/View';
import { useA11y } from './SelectRounded.a11y';
import { useUniqueID } from '../../../hooks/a11yHelper';

const { roundedFocusRing } = commonStyles;
const SelectBorderRadius = 9;
const StyledWrapper = styled(View)<{
  isKeyboardFocus: boolean;
  width?: string | number;
  isError?: boolean;
}>(({ isKeyboardFocus, width = 320, isError, theme: { colors, themeVersion } }) => {
  const finalWidthString = typeof width === 'number' ? `${width}px` : width;

  return {
    ...roundedFocusRing(colors.periwinkleGrey, isKeyboardFocus, isError, themeVersion),
    borderRadius: SelectBorderRadius,
    alignSelf: 'center',
    // helps mitigate slight UI shift on focus
    width: isKeyboardFocus ? `calc(${finalWidthString} + 3px)` : width,
  };
});

const scrollBarStyles = (colors: EmotionTheme['colors']) => {
  return {
    '&::-webkit-scrollbar-track': {
      backgroundColor: 'transparent',
      width: 10,
    },
    '&::-webkit-scrollbar-button': {
      width: 0,
      height: 0,
      display: 'none',
    },
    '&::-webkit-scrollbar-corner': {
      backgroundColor: 'transparent',
    },
    '&::-webkit-scrollbar-thumb': {
      borderRadius: 200,
      backgroundClip: 'padding-box',
      backgroundColor: colors.a11yLinkWaterGrey,
    },
    '&::-webkit-scrollbar': {
      width: 4,
      borderRadius: 2,
      backgroundColor: 'transparent',
    },
    '::-webkit-scrollbar-track-piece': {
      backgroundColor: 'transparent',
      margin: 6,
    },
  };
};

const roundedStyles = (
  colors: EmotionTheme['colors'],
  isError: boolean
): Styles<OptionType, boolean> => {
  return {
    container: (provided) => {
      return {
        ...provided,
        marginTop: 0,
        borderWidth: 0,
        flex: 1,
        // this fixes slightly skinnier gap between focus ring and border on the right site of the input- a sticking point for design
        // the gap looks different as you zoom in and out of the page but this seems to be the best compromise
        marginRight: -0.5,
      };
    },
    control: (provided, { hasValue, isMulti }) => {
      return {
        ...provided,
        borderRadius: SelectBorderRadius,
        border: `1px solid ${isError ? colors.torchRed : colors.permaLividBlueNew}`,
        paddingLeft: hasValue && isMulti ? 10 : 20,
        minHeight: 55,
        paddingRight: 2,
        '&:hover': {
          borderColor: isError ? colors.torchRed : colors.permaLividBlueNew,
        },
      };
    },
    input: (provided) => {
      return {
        ...provided,
        // unclear whether a visible caret is needed for accessibility. removing for now
        caretColor: 'transparent',
      };
    },
    indicatorSeparator: () => {
      return { display: 'none' };
    },
    // clearIndicator: () => {
    //   return { display: 'none' };
    // },
    loadingIndicator: () => {
      return { display: 'none' };
    },
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    dropdownIndicator: (provided, { selectProps }) => {
      return {
        ...provided,
        display: 'block',
        position: 'relative',
        top: selectProps.menuIsOpen ? -2 : 3,
        transform: selectProps.menuIsOpen && 'rotate(180deg)',
        color: colors.permaWildBlueYonder,
        '&:hover': {
          color: colors.permaWildBlueYonder,
        },
      };
    },
    valueContainer: (provided) => {
      return {
        ...provided,
        maxHeight: 138,
        overflowY: 'auto',
        marginTop: 2,
        padding: 0,
        ...scrollBarStyles(colors),
      };
    },
    singleValue: (provided) => {
      return {
        ...provided,
        fontWeight: 400,
        fontSize: 16,
      };
    },
    multiValue: (provided, { isFocused }) => {
      return {
        backgroundColor: isFocused ? colors.permaDarkRoyalBlue : colors.a11yRoyalBlue,
        ...roundedFocusRing(colors.a11yRoyalBlue, isFocused),
        margin: 2,
        // helps mitigate focus ring cut off by valueContainer padding
        marginLeft: isFocused ? 2.5 : undefined,
        '&:hover': {
          backgroundColor: colors.permaDarkRoyalBlue,
        },
      };
    },
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    multiValueRemove: (provided, { isFocused }) => {
      return {
        ...provided,
        backgroundColor: isFocused && colors.permaDarkRoyalBlue,
      };
    },
    option: (provided, { data, isSelected, isFocused, selectProps }) => {
      const borderRadius = 6;
      let activeStyles = {};
      const {
        value,
        isMulti,
        selectProps: { isMouseUser },
      } = selectProps;
      if (!isMouseUser && isFocused) {
        activeStyles = {
          backgroundColor: colors.a11yRoyalBlue,
          color: colors.white,
          ...roundedFocusRing(colors.a11yRoyalBlue, isFocused),
        };
      }

      // isSelected seems to only be accurate for multi-select options, otherwise always evaluates to true after selection is made
      const showSelectedStyling = isMulti
        ? (isSelected && !isFocused) || (isSelected && isFocused && isMouseUser)
        : (data === value && !isFocused) || (data === value && isFocused && isMouseUser);

      return {
        ...provided,
        borderRadius,
        backgroundColor: 'transparent',
        color: showSelectedStyling ? colors.permaDarkRoyalBlue : colors.black,
        fontWeight: showSelectedStyling ? 500 : 400,
        minHeight: 54,
        // helps mitigate slight UI shift on focus
        paddingTop: isFocused && !isMouseUser ? 14 : 16,
        paddingBottom: isFocused && !isMouseUser ? 14 : 16,
        paddingLeft: 20,
        '&:hover': isMouseUser
          ? {
              backgroundColor: colors.a11yRoyalBlue,
              color: colors.white,
              fontWeight: 400,
            }
          : {},
        ...activeStyles,
      };
    },
    menu: (provided, { selectProps: { menuBorderColor } }) => {
      return {
        ...provided,
        borderRadius: 9,
        border: `1px solid ${menuBorderColor || colors.a11yLinkWaterGrey}`,
        paddingRight: 6,
        marginTop: 8,
        boxShadow: '2px 10px 20px 0 rgba(0,0,0,0.15)',
        // prevents footer from rendering on top of menu in quickmatch on zoomed in desktops
        zIndex: 101,
      };
    },
    menuList: (provided) => {
      return {
        ...provided,
        ...scrollBarStyles(colors),
        padding: 6,
        paddingRight: 3,
        maxHeight: 199,
        textAlign: 'left',
      };
    },
    placeholder: (provided) => {
      return {
        ...provided,
        color: colors.darkBlue,
        fontSize: 16,
        fontWeight: 400,
        font: 'roboto',
      };
    },
  };
};

export type SelectRoundedProps = React.ComponentProps<
  typeof Select & { wrapperStyle: EmotionStyle; menuBorderColor?: string }
>;

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
const SelectRounded = ({
  styles,
  components,
  width,
  inputId,
  isError = false,
  // ensures that mobile keyboards don't render for dropdowns that we don't need/want to be searchable
  isInputReadOnly = true,
  dataQa,
  wrapperStyle,
  shouldDisplayLock = true,
  dataTestID,
  shouldDisplayFocus = true,
  ...otherProps
}: SelectRoundedProps) => {
  const { colors } = useEmotionTheme();
  const noPropInputId = useUniqueID('noPropInputId');
  const finalInputId = inputId || noPropInputId;
  const {
    isMouseUser,
    isFocused,
    decreaseTopMargin,
    setDecreaseTopMargin,
    onKeyDown,
    onKeyUp,
    onMouseDown,
    onMouseEnter,
    onMouseMove,
    onTouchStart,
    onFocus,
    onBlur,
    wrapperRef,
  } = useA11y(isInputReadOnly, finalInputId);

  return (
    <StyledWrapper
      ref={wrapperRef}
      isKeyboardFocus={isFocused && !isMouseUser && shouldDisplayFocus}
      style={{ marginTop: decreaseTopMargin ? 10 : 16, ...wrapperStyle }}
      width={width}
      // Typically we want to avoid passing any mouse or keyboard functionality to our View component but this is a special case where we need click
      // and touch events to propagate to the inner input of react-select, something that TouchableView wouldn't allow. Also, the click and touch
      // events on the wrapper only effect styling as opposed to having meaningful functionality
      onMouseDown={onMouseDown}
      onTouchStart={onTouchStart}
      onMouseMove={onMouseMove}
      onKeyDown={onKeyDown}
      onKeyUp={onKeyUp}
      // An analogous onMouseLeave is not necessary and would actually cause problems. If the dropdown is opened via a click, the only way to close it
      // is by selecting an option or clicking outside, which in turn triggers onBlur and thus resets isMouseUser to false (yay). Were onMouseLeave to
      // reset isMouseUser to false, keyboard-specific focus styling for the dropdown options would render upon the mouse exiting the dropdown (boo).
      onMouseEnter={onMouseEnter}
      isError={isError}
    >
      <Select
        dataTestID={dataTestID}
        inputId={finalInputId}
        {...otherProps}
        dataQa={dataQa}
        onFocus={onFocus}
        onBlur={onBlur}
        styles={
          styles
            ? mergeStyles(roundedStyles(colors, isError), styles)
            : roundedStyles(colors, isError)
        }
        lockStyles={{
          // This places the lock icon on top of the dropdown icon
          backgroundColor: 'white',
          width: 15,
          height: 17,
          top: 20,
          right: 15,
          ...(!shouldDisplayLock && { display: 'none' }),
        }}
        displayStaticPlaceholder
        selectProps={{ isMouseUser }}
        components={{
          ...components,
        }}
        showIndicatorBeforeClick
        // react-select single select is super problematic for screen readers if isSearchable={false} so please do not override this
        // If you explicitly want a dropdown to be searchable use isInputReadOnly={false}
        isSearchable
        // This is meant to ensure that the wrapper conforms to the input/control instead of wrapping around/outside of the placeholder when placeHolderIsUp
        setDecreaseTopMargin={setDecreaseTopMargin}
      />
    </StyledWrapper>
  );
};

export default SelectRounded;
