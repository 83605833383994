import { components, ControlProps } from 'react-select';
import SelectRounded, { SelectRoundedProps } from './SelectRounded';
import { OptionType } from '../../../components/Select';
import View from '../../../components/View';
import PasswordX from '../../../components/Svgs/PasswordX';
import { useEmotionTheme } from '../../../core/styled';
import { SelectRoundedVariant } from '../../types';
import { getSelectStyles } from '../../styles/selectStyles';

const Control = ({ children, ...props }: ControlProps<OptionType, false>) => {
  const { icon } = props.selectProps;

  return (
    <components.Control {...props}>
      {icon && (
        <View style={{ marginRight: 4 }} as="span">
          {icon}
        </View>
      )}
      {children}
    </components.Control>
  );
};

const MultiValueRemoveComponent = (props) => {
  const { colors } = useEmotionTheme();
  return (
    <View
      style={{
        justifyContent: 'center',
        alignItems: 'center',
        padding: 0,
        cursor: 'pointer',
      }}
      {...props}
    >
      <PasswordX color={colors.white} height={10} width={10} />
    </View>
  );
};

const MultiValueRemoveComponentSlim = (props) => {
  const { colors } = useEmotionTheme();
  return (
    <View
      style={{
        justifyContent: 'center',
        alignItems: 'center',
        padding: 0,
        cursor: 'pointer',
      }}
      {...props}
    >
      <PasswordX color={colors.white} height={8} width={8} />
    </View>
  );
};

export interface SelectV1Props extends SelectRoundedProps {
  variant?: SelectRoundedVariant;
  icon?: React.ReactNode;
}

const SelectRoundedV1 = (props: SelectV1Props) => {
  const { colorRoles } = useEmotionTheme();
  const { variant } = props;
  return (
    <SelectRounded
      {...props}
      styles={getSelectStyles(colorRoles, variant || 'medium')}
      components={{
        MultiValueRemove:
          variant === 'slim' ? MultiValueRemoveComponentSlim : MultiValueRemoveComponent,
        Control,
      }}
      preferThemeStyles
    />
  );
};
export default SelectRoundedV1;
