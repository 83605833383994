import { Styles, StylesConfig } from 'react-select';
import { OptionType } from '../../components/Select';
import { ColorRolesVersion, SelectRoundedVariant } from '../types';
import { spacing } from '../tokens';
import getMenuInteractionStyles from './interactiveMenuStyles';

const { space0, space050, space100, space150, space200 } = spacing;

const hasSearchResults = (children: React.ReactNode) =>
  Array.isArray(children) && children.length > 0;

// eslint-disable-next-line import/prefer-default-export
export const getSelectStyles = (
  colorRoles: ColorRolesVersion,
  variant: SelectRoundedVariant
): Styles<OptionType, boolean> => {
  return {
    input: (provided) => {
      const variantStyle = variant === 'slim' ? { padding: 0, minHeight: 0 } : {};
      return { ...provided, ...variantStyle };
    },
    container: (provided) => {
      const variantStyle = variant === 'slim' ? { padding: 0, minHeight: 0 } : {};
      return { ...provided, ...variantStyle, marginTop: 0 };
    },
    valueContainer: (provided) => {
      const variantStyle = variant === 'slim' ? { padding: '0 6px', minHeight: 0 } : {};
      return { ...provided, ...variantStyle };
    },
    control: (provided) => {
      const variantStyle = variant === 'slim' ? { padding: 0, minHeight: 0 } : {};
      return { ...provided, ...variantStyle };
    },
    multiValue: (provided) => {
      const variantStyle =
        variant === 'slim'
          ? {
              padding: '3px 8px',
            }
          : {
              padding: '6px 12px',
            };
      return {
        ...provided,
        backgroundColor: colorRoles.system.interactivePrimaryDefault,
        '&:hover': {
          backgroundColor: colorRoles.system.interactivePrimaryHovered,
        },
        '&:focus': {
          backgroundColor: colorRoles.system.interactivePrimaryPressed,
        },
        '&:active': {
          backgroundColor: colorRoles.system.interactivePrimaryPressed,
        },
        ...variantStyle,
      };
    },
    singleValue: (provided) => {
      const variantStyle =
        variant === 'slim'
          ? {
              padding: '3px 8px',
            }
          : {
              padding: '6px 12px',
            };
      return {
        ...provided,
        ...variantStyle,
      };
    },
    multiValueLabel: (provided) => {
      const variantStyle =
        variant === 'slim'
          ? {
              fontSize: 14,
              padding: '0 6px 0 0',
              paddingLeft: 0,
              paddingRight: 6,
            }
          : {
              fontSize: 16,
              padding: '0 8px 0 0',
              paddingLeft: 0,
              paddingRight: 8,
            };
      return {
        ...provided,
        color: 'white',
        fontWeight: 400,
        margin: 0,
        ...variantStyle,
      };
    },
  };
};

export const getInputfieldDropdownStyles = (
  colorRoles: ColorRolesVersion
): StylesConfig<OptionType, boolean> => {
  return {
    input: (provided) => {
      return {
        ...provided,
        padding: 0,
        caretColor: colorRoles.typography.textDefault,
      };
    },
    control: (provided, props) => {
      const {
        menuIsOpen,
        selectProps: { hasError },
      } = props;

      return {
        ...provided,
        padding: `${space100}px ${space200}px`,
        border: `1px solid ${colorRoles.borders.borderInteractiveDefault}`,
        backgroundColor: colorRoles.surfaces.neutralSubtleDefault,
        '&:hover': {
          border: `1px solid ${colorRoles.borders.interactiveBoldHovered}`,
        },
        ...(menuIsOpen && {
          border: `1px solid ${colorRoles.borders.interactiveBoldDefault}`,
        }),
        ...(hasError && {
          border: `1px solid ${colorRoles.borders.borderCriticalDefault}`,
          '&:hover': {
            border: `1px solid ${colorRoles.borders.borderCriticalDefault}`,
          },
        }),
      };
    },
    menu: (provided) => {
      return {
        ...provided,
        backgroundColor: 'unset',
        border: `unset`,
        boxShadow: 'unset',
        borderRadius: space100,
        padding: `${space0}px ${space0}px`,
      };
    },
    menuList: (provided, props) => {
      const { children } = props;

      const menuListContainerStyles = {
        backgroundColor: colorRoles.surfaces.neutralSubtleDefault,
        boxShadow: '0 2px 12px 0 rgba(21, 17, 16, 0.2)',
        border: `1px solid ${colorRoles.borders.borderInteractiveDefault}`,
        borderRadius: space100,
      };

      return {
        ...provided,
        ...(hasSearchResults(children) ? menuListContainerStyles : {}),
        padding: space0,
        '&::-webkit-scrollbar': {
          width: 12,
          backgroundColor: 'transparent',
        },
        '&::-webkit-scrollbar-thumb': {
          borderLeft: `4px solid transparent`,
          borderRight: `4px solid transparent`,
          backgroundClip: 'padding-box',
          backgroundColor: colorRoles.surfaces.brandBoldDisabled,
        },
      };
    },
    option: (provided, props) => {
      const { isSelected } = props;

      return {
        ...provided,
        minHeight: 54,
        paddingLeft: 20,
        padding: `${space150}px ${space200}px`,
        borderRadius: space0,
        width: '100%',
        boxShadow: 'unset',
        border: 'unset',
        ...getMenuInteractionStyles(colorRoles, isSelected),
      };
    },
    indicatorsContainer: (provided, props) => {
      const { menuIsOpen } = props.selectProps;

      return {
        ...provided,
        padding: space0,
        gap: space050,
        color: colorRoles.icons.iconSubtlestDefault,
        ...(menuIsOpen && {
          '&:hover': {
            top: 3,
          },
        }),
      };
    },
    dropdownIndicator: (provided) => {
      return {
        ...provided,
        padding: space0,
      };
    },
    clearIndicator: (provided, props) => {
      const { menuIsOpen } = props.selectProps;

      return { ...provided, display: menuIsOpen ? 'block' : 'none' };
    },
    placeholder: (provided, props) => {
      const { selectProps, hasValue } = props;

      return { ...provided, paddingLeft: selectProps.leftIcon && !hasValue ? 28 : space0 };
    },
    singleValue: (provided, props) => {
      const {
        selectProps: { leftIcon },
      } = props;

      return { ...provided, paddingLeft: leftIcon ? 28 : space0 };
    },
  };
};
