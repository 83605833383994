import { withDSIconMaker } from './utils/IconMaker';
import { IconConfig } from './types/types';

const standardPath = '';
const filledStandardPath =
  'M8 16C10.1217 16 12.1566 15.1571 13.6569 13.6569C15.1571 12.1566 16 10.1217 16 8C16 5.87827 15.1571 3.84344 13.6569 2.34315C12.1566 0.842855 10.1217 0 8 0C5.87827 0 3.84344 0.842855 2.34315 2.34315C0.842855 3.84344 0 5.87827 0 8C0 10.1217 0.842855 12.1566 2.34315 13.6569C3.84344 15.1571 5.87827 16 8 16ZM5.46875 5.46875C5.7625 5.175 6.2375 5.175 6.52812 5.46875L7.99687 6.9375L9.46562 5.46875C9.75937 5.175 10.2344 5.175 10.525 5.46875C10.8156 5.7625 10.8187 6.2375 10.525 6.52812L9.05625 7.99687L10.525 9.46562C10.8187 9.75937 10.8187 10.2344 10.525 10.525C10.2312 10.8156 9.75625 10.8187 9.46562 10.525L7.99687 9.05625L6.52812 10.525C6.23438 10.8187 5.75938 10.8187 5.46875 10.525C5.17812 10.2312 5.175 9.75625 5.46875 9.46562L6.9375 7.99687L5.46875 6.52812C5.175 6.23438 5.175 5.75938 5.46875 5.46875Z';
const majorPath =
  'M12 4.66797C14.1549 4.66797 16.2215 5.52399 17.7452 7.04773C19.269 8.57146 20.125 10.6381 20.125 12.793C20.125 14.9479 19.269 17.0145 17.7452 18.5382C16.2215 20.0619 14.1549 20.918 12 20.918C9.84512 20.918 7.77849 20.0619 6.25476 18.5382C4.73102 17.0145 3.875 14.9479 3.875 12.793C3.875 10.6381 4.73102 8.57146 6.25476 7.04773C7.77849 5.52399 9.84512 4.66797 12 4.66797ZM12 22.793C14.6522 22.793 17.1957 21.7394 19.0711 19.864C20.9464 17.9887 22 15.4451 22 12.793C22 10.1408 20.9464 7.59726 19.0711 5.7219C17.1957 3.84654 14.6522 2.79297 12 2.79297C9.34784 2.79297 6.8043 3.84654 4.92893 5.7219C3.05357 7.59726 2 10.1408 2 12.793C2 15.4451 3.05357 17.9887 4.92893 19.864C6.8043 21.7394 9.34784 22.793 12 22.793ZM8.83594 9.62891C8.46875 9.99609 8.46875 10.5898 8.83594 10.9531L10.6719 12.7891L8.83594 14.625C8.46875 14.9922 8.46875 15.5859 8.83594 15.9492C9.20312 16.3125 9.79687 16.3164 10.1602 15.9492L11.9961 14.1133L13.832 15.9492C14.1992 16.3164 14.793 16.3164 15.1562 15.9492C15.5195 15.582 15.5234 14.9883 15.1562 14.625L13.3203 12.7891L15.1562 10.9531C15.5234 10.5859 15.5234 9.99219 15.1562 9.62891C14.7891 9.26562 14.1953 9.26172 13.832 9.62891L11.9961 11.4648L10.1602 9.62891C9.79297 9.26172 9.19922 9.26172 8.83594 9.62891Z';
const filledMajorPath =
  'M12 22C14.6522 22 17.1957 20.9464 19.0711 19.0711C20.9464 17.1957 22 14.6522 22 12C22 9.34784 20.9464 6.8043 19.0711 4.92893C17.1957 3.05357 14.6522 2 12 2C9.34784 2 6.8043 3.05357 4.92893 4.92893C3.05357 6.8043 2 9.34784 2 12C2 14.6522 3.05357 17.1957 4.92893 19.0711C6.8043 20.9464 9.34784 22 12 22ZM8.83594 8.83594C9.20312 8.46875 9.79687 8.46875 10.1602 8.83594L11.9961 10.6719L13.832 8.83594C14.1992 8.46875 14.793 8.46875 15.1562 8.83594C15.5195 9.20312 15.5234 9.79687 15.1562 10.1602L13.3203 11.9961L15.1562 13.832C15.5234 14.1992 15.5234 14.793 15.1562 15.1562C14.7891 15.5195 14.1953 15.5234 13.832 15.1562L11.9961 13.3203L10.1602 15.1562C9.79297 15.5234 9.19922 15.5234 8.83594 15.1562C8.47266 14.7891 8.46875 14.1953 8.83594 13.832L10.6719 11.9961L8.83594 10.1602C8.46875 9.79297 8.46875 9.19922 8.83594 8.83594Z';

const pathConfig: IconConfig = {
  standard: {
    default: {
      path: standardPath,
    },
    filled: {
      path: filledStandardPath,
    },
  },
  major: {
    default: {
      path: majorPath,
    },
    filled: {
      path: filledMajorPath,
    },
  },
};

const CircleXMark = withDSIconMaker(pathConfig, 'x inside filled circle');

export default CircleXMark;
